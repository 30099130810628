export default () => {
  // This is to make the article automatically appear in smaller size.
  try {
    localStorage.setItem('intercom.intercom-expanded-mode', 'false');
  } catch (e) {
    //
  }

  document.addEventListener('click', (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    const anchorElement = getAnchorElement(event.target);

    if (!anchorElement) {
      return;
    }

    const articleId = anchorElement.href?.match(
      /help\.clearstream\.io\/en\/articles\/(\d+)/,
    )?.[1];

    if (!articleId) {
      return;
    }

    // In some cases Intercom fails to load.
    if (!window.Intercom) {
      return;
    }

    event.preventDefault();
    window.Intercom('showArticle', articleId);
  });
};

function getAnchorElement(el) {
  if (el.tagName === 'A') {
    return el;
  }
  if (el.parentElement) {
    return getAnchorElement(el.parentElement);
  }
  return null;
}
